//
// Transform
//
@mixin transform($data...) {
  -webkit-transform: $data;
  -moz-transform: $data;
  -ms-transform: $data;
  transform: $data;
}

//
// Animations
//
@mixin animation($name, $duration, $timing, $delay: 0s, $iteration: 1, $direction: normal) {
  -webkit-animation: $name $duration $timing $delay $iteration $direction;
  -moz-animation: $name $duration $timing $delay $iteration $direction;
  -o-animation: $name $duration $timing $delay $iteration $direction;
  animation: $name $duration $timing $delay $iteration $direction;
  animation-name: $name;
  animation-duration: $duration;
  animation-timing-function: $timing;
  animation-delay: $delay;
  animation-iteration-count: $iteration;
  animation-direction: $direction;
  animation-play-state: running;
}

// TODO https://angular.io/guide/update-to-version-15#v15-bc-03
@mixin keyframes($name) {
  @-webkit-keyframes #{$name} {
    @content;
  }
  @-moz-keyframes #{$name} {
    @content;
  }
  @-o-keyframes #{$name} {
    @content;
  }
  @keyframes #{$name} {
    @content;
  }
}
