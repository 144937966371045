:root {
  --yc-bg: #f8f9fe;
  --yc-bg-rgb: 248, 249, 254;
  --yc-text-color: #2f2f3b;
  --yc-layout-main-padding: 1.5rem;
  --yc-font-family: "Averta", "Helvetica Neue", "Segoe UI", helvetica, verdana, sans-serif;
  --yc-small-input-font-size: 12px;

  --yc-color-primary: #7366ff;
  --yc-color-secondary-bg: #e9ecef;
  --yc-color-primary-lighter: #8c82ff;
  --yc-color-primary-tint: #f1f0ff;
  --yc-color-primary-darker: #f4f2fd;
  --yc-color-primary-dark: #2f2f3b;
  --yc-color-accent: #ff3364;
  --yc-color-warn: #fc4438;
  --yc-color-success: #2dd36f;
  --yc-text-color-light: #fff;
  --yc-sidebar-item-dark-lighter: #565b65;
  --yc-background-color-light: rgba(242, 243, 247, 0.7);

  --yc-heading-color: #2f2f3b;
  --yc-header-height: 72px;
  --yc-header-shadow: 0 0 21px 0 rgba(89, 102, 122, 0.1);

  --yc-section-separator-color: #f3f2ff;

  --yc-link-color: #2f2f3b;
  --yc-link-hover-color: #007bff;

  --yc-card-page-border-radius: 10px;
  --yc-card-page-shadow: 0 0 37px rgba(8, 21, 66, 0.05);

  --yc-card-border-radius: 15px;
  --yc-card-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
  --yc-card-hover-shadow: 0 0 40px rgba(8, 21, 66, 0.05);

  --mdc-form-field-border-color: #dee2e6;

  --yc-phi-max: 61.8%;
  --yc-phi-min: 38.2%;

  --yc-widget-text: #242934;
  --yc-widget-text-sub: rgba(82, 82, 108, 0.8);
  --yc-widget-orange: #feb019; // Identique aux graphs Apex Charts
  --yc-widget-green: #00e396; // Identique aux graphs Apex Charts
  --yc-widget-red: #ff4560; // Identique aux graphs Apex Charts
  --yc-widget-cyan: #71c1f6;

  --yc-blue: #0d6efd;
  --yc-indigo: #6610f2;
  --yc-purple: #6f42c1;
  --yc-pink: #d63384;
  --yc-red: #dc3545;
  --yc-orange: #fd7e14;
  --yc-yellow: #ffc107;
  --yc-green: #198754;
  --yc-teal: #20c997;
  --yc-cyan: #0dcaf0;

  --yc-natural-easing: cubic-bezier(0.4, 0, 0.2, 1);
  --yc-ui-animation: 0.2s var(--yc-natural-easing);
}
