// Tailles par défaut pour les skeletons
h2,
h4 {
  &:has(> yc-skeleton) {
    max-width: var(--yc-phi-max);
    margin: 0;
  }
}

h3,
h5 {
  &:has(> yc-skeleton) {
    max-width: var(--yc-phi-min);
    margin: 0;
  }
}
