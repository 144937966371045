*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  color: var(--yc-text-color);
  background-color: var(--yc-bg);
  overflow: hidden;
}

hr {
  border: none;
  border-top: 1px solid var(--yc-section-separator-color);
}

// Reset des paragraphes
p,
.mat-typography p {
  margin: 0;
}

* + p,
.mat-typography * + p {
  margin-top: 1em;
}
