//---------------------------------------
//  Typography
//---------------------------------------

// Averta base path
$font-base-path: "/assets/fonts" !default;

@font-face {
  font-family: "Averta";
  font-weight: 100;
  font-display: swap;
  src:
    local("Averta"),
    url("#{$font-base-path}/Averta-ExtraLight.woff2") format("woff2"),
    url("#{$font-base-path}/Averta-ExtraLight.otf") format("opentype"),
    url("#{$font-base-path}/Averta-ExtraLight.ttf") format("truetype");
}

@font-face {
  font-family: "Averta";
  font-weight: 200;
  font-display: swap;
  src:
    local("Averta"),
    url("#{$font-base-path}/Averta-Light.woff2") format("woff2"),
    url("#{$font-base-path}/Averta-Ligth.otf") format("opentype"),
    url("#{$font-base-path}/Averta-Light.ttf") format("truetype");
}

@font-face {
  font-family: "Averta";
  font-weight: 400;
  font-display: swap;
  src:
    local("Averta"),
    url("#{$font-base-path}/Averta-Regular.woff2") format("woff2"),
    url("#{$font-base-path}/Averta-Regular.otf") format("opentype"),
    url("#{$font-base-path}/Averta-Regular.ttf") format("truetype");
}

@font-face {
  font-family: "Averta";
  font-weight: 500;
  font-display: swap;
  src:
    local("Averta"),
    url("#{$font-base-path}/Averta-SemiBold.woff2") format("woff2"),
    url("#{$font-base-path}/Averta-SemiBold.otf") format("opentype"),
    url("#{$font-base-path}/Averta-SemiBold.ttf") format("truetype");
}

@font-face {
  font-family: "Averta";
  font-weight: 600;
  font-display: swap;
  src:
    local("Averta"),
    url("#{$font-base-path}/Averta-Bold.woff2") format("woff2"),
    url("#{$font-base-path}/Averta-Bold.otf") format("opentype"),
    url("#{$font-base-path}/Averta-Bold.ttf") format("truetype");
}
