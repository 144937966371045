.apexcharts-tooltip {
  border: none !important;
  z-index: 2 !important;

  .tooltip {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2px;
    border: none;
    font-size: var(--yc-small-input-font-size);
    background-color: var(--yc-apexcharts-tooltip-bg-color, #e0e1e7);
    line-height: 1;

    div {
      padding: 0.35em 0.5em;
      text-align: center;
      width: 100%;
      flex: 1;
    }

    .label {
      color: var(--yc-apexcharts-tooltip-label-color, #fff);
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
    }

    .value {
      background-color: #fff;
      color: #000;
      border-radius: 3px;
      display: inline-block;
      height: 100%;
      padding: 0.5em 0.65em;
      font-size: 14px;
      text-align: center;

      span {
        font-weight: 600;
      }
    }

    td {
      text-align: right;

      & + td {
        text-align: left;
        font-weight: 500;
      }
    }
  }
}
