:root {
  --yc-bg: #f8f9fe;
  --yc-bg-rgb: 248, 249, 254;
  --yc-text-color: #2f2f3b;
  --yc-layout-main-padding: 1.5rem;
  --yc-font-family: "Averta", "Helvetica Neue", "Segoe UI", helvetica, verdana, sans-serif;
  --yc-small-input-font-size: 12px;
  --yc-color-primary: #7366ff;
  --yc-color-secondary-bg: #e9ecef;
  --yc-color-primary-lighter: #8c82ff;
  --yc-color-primary-tint: #f1f0ff;
  --yc-color-primary-darker: #f4f2fd;
  --yc-color-primary-dark: #2f2f3b;
  --yc-color-accent: #ff3364;
  --yc-color-warn: #fc4438;
  --yc-color-success: #2dd36f;
  --yc-text-color-light: #fff;
  --yc-sidebar-item-dark-lighter: #565b65;
  --yc-background-color-light: rgba(242, 243, 247, 0.7);
  --yc-heading-color: #2f2f3b;
  --yc-header-height: 72px;
  --yc-header-shadow: 0 0 21px 0 rgba(89, 102, 122, 0.1);
  --yc-section-separator-color: #f3f2ff;
  --yc-link-color: #2f2f3b;
  --yc-link-hover-color: #007bff;
  --yc-card-page-border-radius: 10px;
  --yc-card-page-shadow: 0 0 37px rgba(8, 21, 66, 0.05);
  --yc-card-border-radius: 15px;
  --yc-card-shadow: 0px 9px 20px rgba(46, 35, 94, 0.07);
  --yc-card-hover-shadow: 0 0 40px rgba(8, 21, 66, 0.05);
  --mdc-form-field-border-color: #dee2e6;
  --yc-phi-max: 61.8%;
  --yc-phi-min: 38.2%;
  --yc-widget-text: #242934;
  --yc-widget-text-sub: rgba(82, 82, 108, 0.8);
  --yc-widget-orange: #feb019;
  --yc-widget-green: #00e396;
  --yc-widget-red: #ff4560;
  --yc-widget-cyan: #71c1f6;
  --yc-blue: #0d6efd;
  --yc-indigo: #6610f2;
  --yc-purple: #6f42c1;
  --yc-pink: #d63384;
  --yc-red: #dc3545;
  --yc-orange: #fd7e14;
  --yc-yellow: #ffc107;
  --yc-green: #198754;
  --yc-teal: #20c997;
  --yc-cyan: #0dcaf0;
  --yc-natural-easing: cubic-bezier(0.4, 0, 0.2, 1);
  --yc-ui-animation: 0.2s var(--yc-natural-easing);
}

*:not(.mat-icon) {
  font-family: var(--yc-font-family) !important;
}

body.hn *:not(.mat-icon) {
  font-family: var(--yc-font-family) !important;
  -webkit-font-smoothing: antialiased;
}

h3 {
  font-weight: 600 !important;
  font-size: 20px !important;
}

h5 {
  font-weight: 600 !important;
}

@font-face {
  font-family: "Averta";
  font-weight: 100;
  font-display: swap;
  src: local("Averta"), url("/assets/fonts/Averta-ExtraLight.woff2") format("woff2"), url("/assets/fonts/Averta-ExtraLight.otf") format("opentype"), url("/assets/fonts/Averta-ExtraLight.ttf") format("truetype");
}
@font-face {
  font-family: "Averta";
  font-weight: 200;
  font-display: swap;
  src: local("Averta"), url("/assets/fonts/Averta-Light.woff2") format("woff2"), url("/assets/fonts/Averta-Ligth.otf") format("opentype"), url("/assets/fonts/Averta-Light.ttf") format("truetype");
}
@font-face {
  font-family: "Averta";
  font-weight: 400;
  font-display: swap;
  src: local("Averta"), url("/assets/fonts/Averta-Regular.woff2") format("woff2"), url("/assets/fonts/Averta-Regular.otf") format("opentype"), url("/assets/fonts/Averta-Regular.ttf") format("truetype");
}
@font-face {
  font-family: "Averta";
  font-weight: 500;
  font-display: swap;
  src: local("Averta"), url("/assets/fonts/Averta-SemiBold.woff2") format("woff2"), url("/assets/fonts/Averta-SemiBold.otf") format("opentype"), url("/assets/fonts/Averta-SemiBold.ttf") format("truetype");
}
@font-face {
  font-family: "Averta";
  font-weight: 600;
  font-display: swap;
  src: local("Averta"), url("/assets/fonts/Averta-Bold.woff2") format("woff2"), url("/assets/fonts/Averta-Bold.otf") format("opentype"), url("/assets/fonts/Averta-Bold.ttf") format("truetype");
}
:root {
  --mat-option-label-text-size: 14px;
  --mat-form-field-container-text-size: 14px;
  --mat-select-trigger-text-size: var(--mat-form-field-container-text-size);
  --mdc-outlined-text-field-label-text-size: var(--mat-form-field-container-text-size);
  --mdc-typography-body1-font-size: 14px;
  --mdc-typography-subtitle1-font-size: var(--yc-small-input-font-size);
  --mat-form-field-container-height: 42px;
  --mat-form-field-container-vertical-padding: 12px;
  --mat-autocomplete-container-elevation-shadow: var(--yc-card-shadow);
  --mdc-slider-handle-color: var(--yc-color-primary);
  --mdc-slider-active-track-color: var(--yc-color-primary);
}

.mat-body,
.mat-body-2,
.mat-typography,
.mat-typography *,
.mat-mdc-form-field,
.mat-mdc-floating-label,
.mdc-button__label {
  letter-spacing: initial !important;
}

.mat-mdc-form-field-icon-suffix .mdc-button {
  min-width: 42px;
}

.mat-mdc-form-field-infix {
  min-height: 48px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mat-mdc-form-field-infix {
  padding-top: 12px;
  padding-bottom: 12px;
}
.mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(-30.5px)
    scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
}

body .mat-mdc-form-field.small {
  --mat-form-field-container-height: 36px;
  --mat-form-field-container-text-size: var(--yc-small-input-font-size);
}
body .mat-mdc-form-field:not(.small) .mat-mdc-text-field-wrapper .mat-mdc-form-field-flex .mat-mdc-floating-label {
  top: calc(2px + var(--mat-form-field-container-height) / 2);
}
body .mat-mdc-form-field:not(.small) .mat-mdc-text-field-wrapper.mdc-text-field--outlined .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
  --mat-mdc-form-field-label-transform: translateY(
      calc(calc(8.75px + var(--mat-form-field-container-height) / 2) * -1)
    )
    scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
}
body .mat-mdc-form-field .mat-mdc-form-field-icon-suffix > span {
  margin: 0 0.75em 0 0.25em;
}
body .mat-mdc-form-field .mat-mdc-form-field-icon-suffix .mat-mdc-icon-button.clear {
  --mdc-icon-button-state-layer-size: 32px;
  --mdc-icon-button-icon-size: 16px;
  padding: 8px;
}

.cdk-overlay-pane.w618 {
  width: 61.8vw;
}
.cdk-overlay-pane .mat-mdc-dialog-title {
  text-align: center;
}

.mat-mdc-table {
  box-shadow: var(--yc-card-shadow);
}

.mat-mdc-form-field.mat-form-field-invalid .mat-mdc-form-field-subscript-wrapper {
  padding-bottom: 0.75em;
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) .mdc-notched-outline__leading, .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) .mdc-notched-outline__notch, .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) .mdc-notched-outline__trailing {
  border-color: var(--mdc-form-field-border-color);
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid):not(.mdc-text-field--focused):hover .mdc-notched-outline .mdc-notched-outline__trailing {
  border-color: var(--mdc-form-field-border-color);
}

body .mat-button-toggle-group-appearance-standard {
  border: none;
  border-radius: 0;
}

.mat-button-toggle-appearance-standard {
  --yc-mat-button-toggle-checked-border-color: var(--yc-color-primary);
  --yc-mat-button-toggle-border: 1px solid var(--mat-standard-button-toggle-divider-color);
  border: var(--yc-mat-button-toggle-border);
  border-left: none;
  border-right: none;
  transition: all var(--yc-ui-animation);
}
.mat-button-toggle-appearance-standard:first-child {
  border-left: var(--yc-mat-button-toggle-border);
  border-radius: var(--mat-standard-button-toggle-shape) 0 0 var(--mat-standard-button-toggle-shape);
}
.mat-button-toggle-appearance-standard:last-child {
  border-right: var(--yc-mat-button-toggle-border);
  border-radius: 0 var(--mat-standard-button-toggle-shape) var(--mat-standard-button-toggle-shape) 0;
}
.mat-button-toggle-appearance-standard.mat-button-toggle-checked {
  border-color: var(--yc-mat-button-toggle-checked-border-color);
}
.mat-button-toggle-appearance-standard.mat-button-toggle-checked + .mat-button-toggle-appearance-standard {
  border-left-color: var(--yc-mat-button-toggle-checked-border-color);
}
.mat-button-toggle-appearance-standard:not(.mat-button-toggle-checked):not(.mat-button-toggle-disabled) {
  --mat-standard-button-toggle-hover-state-layer-opacity: 1;
  --mat-standard-button-toggle-state-layer-color: var(--yc-color-primary-tint);
}
.mat-button-toggle-appearance-standard:not(.mat-button-toggle-checked):not(.mat-button-toggle-disabled):hover {
  --mat-standard-button-toggle-text-color: var(--yc-mat-button-toggle-checked-border-color);
  border-color: var(--yc-color-primary-lighter);
}
.mat-button-toggle-appearance-standard:not(.mat-button-toggle-checked):not(.mat-button-toggle-disabled):hover + .mat-button-toggle-appearance-standard {
  border-left-color: var(--yc-mat-button-toggle-checked-border-color);
}
.mat-button-toggle-appearance-standard .mat-button-toggle-button {
  z-index: 1;
  font-size: var(--yc-small-input-font-size);
}
.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
  --mat-standard-button-toggle-height: 32px;
}

body .mat-mdc-unelevated-button,
body .mat-mdc-raised-button {
  font-weight: 400;
  font-size: var(--yc-small-input-font-size);
  border-radius: 6px;
  box-shadow: none;
}
body .mat-mdc-unelevated-button:not(:disabled),
body .mat-mdc-raised-button:not(:disabled) {
  background-color: var(--yc-color-primary);
  color: #fff;
}
body .mat-mdc-unelevated-button:not(:disabled).mat-unthemed,
body .mat-mdc-raised-button:not(:disabled).mat-unthemed {
  background: none;
  border: 1px solid var(--yc-color-primary);
  color: var(--yc-color-primary);
}
body .mat-mdc-unelevated-button:not(:disabled).mat-default,
body .mat-mdc-raised-button:not(:disabled).mat-default {
  color: #000;
  background-color: rgba(115, 102, 255, 0.06);
}
body .mat-mdc-unelevated-button:not(:disabled).mat-warn,
body .mat-mdc-raised-button:not(:disabled).mat-warn {
  background-color: var(--yc-color-accent);
}
body .mat-mdc-unelevated-button:not(:disabled):hover,
body .mat-mdc-raised-button:not(:disabled):hover {
  box-shadow: inset 0 1px 0 rgba(255, 255, 255, 0.15), 0 1px 1px rgba(0, 0, 0, 0.075);
}
body .mat-mdc-unelevated-button .yuzu-icon,
body .mat-mdc-raised-button .yuzu-icon,
body .mat-mdc-outlined-button .yuzu-icon {
  display: inline-block;
  position: relative;
  vertical-align: top;
  font-size: 1.125rem;
  height: 1.125rem;
  width: 1.125rem;
  margin-left: -4px;
  margin-right: 8px;
}
body .mat-mdc-dialog-actions {
  justify-content: flex-end;
  overflow: hidden;
}
body .mat-button-toggle .mat-pseudo-checkbox {
  display: none;
}
body .mat-mdc-optgroup-label {
  min-height: 24px;
  font-size: 0.75em;
  background-image: url(/assets/img/profile-bg.png);
  background-repeat: no-repeat;
  background-size: 120%;
  background-position: top center;
  border-radius: 0;
  opacity: 0.6;
}
body .mat-mdc-option:hover:not(.mdc-list-item--disabled) {
  --mat-option-hover-state-layer-color: var(--yc-color-primary-tint);
  --mdc-list-list-item-hover-label-text-color: var(--yc-color-primary);
}
body .mat-mdc-tab {
  --mat-tab-header-label-text-size: 0.75rem;
  --mat-tab-header-active-label-text-color: var(--yc-color-primary);
  --mat-tab-header-active-ripple-color: var(--yc-color-primary);
  --mdc-tab-indicator-active-indicator-color: var(--yc-color-primary);
  --mat-tab-header-active-hover-indicator-color: var(--yc-color-primary);
  --mat-tab-header-active-hover-label-text-color: var(--yc-color-primary);
  --mat-tab-header-inactive-hover-label-text-color: var(--yc-color-primary);
  --mat-tab-header-active-focus-label-text-color: var(--yc-color-primary);
  --mat-tab-header-inactive-focus-label-text-color: var(--yc-color-primary);
  --mat-tab-header-active-focus-indicator-color: var(--yc-color-primary);
}
body div.mat-mdc-autocomplete-panel {
  max-height: min(512px, 100vh);
}

.apexcharts-tooltip {
  border: none !important;
  z-index: 2 !important;
}
.apexcharts-tooltip .tooltip {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2px;
  border: none;
  font-size: var(--yc-small-input-font-size);
  background-color: var(--yc-apexcharts-tooltip-bg-color, #e0e1e7);
  line-height: 1;
}
.apexcharts-tooltip .tooltip div {
  padding: 0.35em 0.5em;
  text-align: center;
  width: 100%;
  flex: 1;
}
.apexcharts-tooltip .tooltip .label {
  color: var(--yc-apexcharts-tooltip-label-color, #fff);
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}
.apexcharts-tooltip .tooltip .value {
  background-color: #fff;
  color: #000;
  border-radius: 3px;
  display: inline-block;
  height: 100%;
  padding: 0.5em 0.65em;
  font-size: 14px;
  text-align: center;
}
.apexcharts-tooltip .tooltip .value span {
  font-weight: 600;
}
.apexcharts-tooltip .tooltip td {
  text-align: right;
}
.apexcharts-tooltip .tooltip td + td {
  text-align: left;
  font-weight: 500;
}

*,
*:before,
*:after {
  box-sizing: border-box;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  padding: 0;
  color: var(--yc-text-color);
  background-color: var(--yc-bg);
  overflow: hidden;
}

hr {
  border: none;
  border-top: 1px solid var(--yc-section-separator-color);
}

p,
.mat-typography p {
  margin: 0;
}

* + p,
.mat-typography * + p {
  margin-top: 1em;
}

.svg-inline {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;
}
.svg-inline:not(:root) {
  overflow: visible;
}

.g-lg {
  font-size: 1.3333333333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}

.g-xs {
  font-size: 0.75em;
}

.g-sm {
  font-size: 0.875em;
}

.g-2x {
  font-size: 2em;
}

.g-3x {
  font-size: 3em;
}

.g-4x {
  font-size: 4em;
}

.g-5x {
  font-size: 5em;
}

.svg-inline.g-lg {
  vertical-align: -0.225em;
}
.svg-inline .g-primary {
  fill: currentColor;
  opacity: 1;
}
.svg-inline .g-secondary {
  fill: currentColor;
  opacity: 0.4;
}

svg.g-spin,
.g-spin > svg {
  -webkit-animation: g-spin 1.35s linear 0s infinite normal;
  -moz-animation: g-spin 1.35s linear 0s infinite normal;
  -o-animation: g-spin 1.35s linear 0s infinite normal;
  animation: g-spin 1.35s linear 0s infinite normal;
  animation-name: g-spin;
  animation-duration: 1.35s;
  animation-timing-function: linear;
  animation-delay: 0s;
  animation-iteration-count: infinite;
  animation-direction: normal;
  animation-play-state: running;
}

@-webkit-keyframes g-spin {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-moz-keyframes g-spin {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@-o-keyframes g-spin {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes g-spin {
  0% {
    -webkit-transform: rotate(0);
    -moz-transform: rotate(0);
    -ms-transform: rotate(0);
    transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
h2:has(> yc-skeleton),
h4:has(> yc-skeleton) {
  max-width: var(--yc-phi-max);
  margin: 0;
}

h3:has(> yc-skeleton),
h5:has(> yc-skeleton) {
  max-width: var(--yc-phi-min);
  margin: 0;
}