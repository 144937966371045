@use "../mixins/animation" as animation;

.svg-inline {
  display: inline-block;
  font-size: inherit;
  height: 1em;
  overflow: visible;
  vertical-align: -0.125em;

  &:not(:root) {
    overflow: visible;
  }
}

.g-lg {
  font-size: 1.3333333333em;
  line-height: 0.75em;
  vertical-align: -0.0667em;
}

.g-xs {
  font-size: 0.75em;
}
.g-sm {
  font-size: 0.875em;
}
.g-2x {
  font-size: 2em;
}
.g-3x {
  font-size: 3em;
}
.g-4x {
  font-size: 4em;
}
.g-5x {
  font-size: 5em;
}

.svg-inline {
  &.g-lg {
    vertical-align: -0.225em;
  }
  .g-primary {
    fill: currentColor;
    opacity: 1;
  }
  .g-secondary {
    fill: currentColor;
    opacity: 0.4;
  }
}

svg.g-spin,
.g-spin > svg {
  @include animation.animation(g-spin, 1.35s, linear, 0s, infinite, normal);
}

@include animation.keyframes(g-spin) {
  0% {
    @include animation.transform(rotate(0));
  }
  100% {
    @include animation.transform(rotate(360deg));
  }
}
