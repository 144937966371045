// TODO Gus - Refacto
*:not(.mat-icon) {
  font-family: var(--yc-font-family) !important;
}

body.hn {
  *:not(.mat-icon) {
    font-family: var(--yc-font-family) !important;
    -webkit-font-smoothing: antialiased;
  }
}

h3 {
  font-weight: 600 !important;
  font-size: 20px !important;
}

h5 {
  font-weight: 600 !important;
}
