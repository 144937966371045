:root {
  --mat-option-label-text-size: 14px;
  --mat-form-field-container-text-size: 14px;
  --mat-select-trigger-text-size: var(--mat-form-field-container-text-size);
  --mdc-outlined-text-field-label-text-size: var(--mat-form-field-container-text-size);
  --mdc-typography-body1-font-size: 14px;
  --mdc-typography-subtitle1-font-size: var(--yc-small-input-font-size);
  --mat-form-field-container-height: 42px;
  --mat-form-field-container-vertical-padding: 12px;
  --mat-autocomplete-container-elevation-shadow: var(--yc-card-shadow);
  --mdc-slider-handle-color: var(--yc-color-primary);
  --mdc-slider-active-track-color: var(--yc-color-primary);
}

.mat-body,
.mat-body-2,
.mat-typography,
.mat-typography *,
.mat-mdc-form-field,
.mat-mdc-floating-label,
.mdc-button__label {
  letter-spacing: initial !important;
}

.mat-mdc-form-field-icon-suffix {
  .mdc-button {
    min-width: 42px;
  }
}

.mat-mdc-form-field-infix {
  min-height: 48px;
}

.mat-mdc-text-field-wrapper.mdc-text-field--outlined {
  .mat-mdc-form-field-infix {
    padding-top: 12px;
    padding-bottom: 12px;
  }

  .mdc-notched-outline--upgraded .mdc-floating-label--float-above {
    --mat-mdc-form-field-label-transform: translateY(-30.5px)
      scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
  }
}

body {
  .mat-mdc-form-field {
    // Petits champs input (header toolbar, etc.)
    &.small {
      --mat-form-field-container-height: 36px;
      --mat-form-field-container-text-size: var(--yc-small-input-font-size);
    }

    // Champs input par défaut
    &:not(.small) {
      .mat-mdc-text-field-wrapper {
        .mat-mdc-form-field-flex {
          .mat-mdc-floating-label {
            top: calc(2px + var(--mat-form-field-container-height) / 2);
          }
        }

        &.mdc-text-field--outlined {
          .mdc-notched-outline--upgraded {
            .mdc-floating-label--float-above {
              --mat-mdc-form-field-label-transform: translateY(
                  calc(calc(8.75px + var(--mat-form-field-container-height) / 2) * -1)
                )
                scale(var(--mat-mdc-form-field-floating-label-scale, 0.75));
            }
          }
        }
      }
    }

    // Bouton icône pour réinitialiser le contenu d'un champ input
    .mat-mdc-form-field-icon-suffix {
      & > span {
        margin: 0 0.75em 0 0.25em;
      }

      .mat-mdc-icon-button {
        &.clear {
          --mdc-icon-button-state-layer-size: 32px;
          --mdc-icon-button-icon-size: 16px;
          padding: 8px;
        }
      }
    }
  }
}

.cdk-overlay-pane {
  &.w618 {
    width: 61.8vw;
  }

  .mat-mdc-dialog-title {
    text-align: center;
  }
}

.mat-mdc-table {
  box-shadow: var(--yc-card-shadow);
}

.mat-mdc-form-field.mat-form-field-invalid {
  .mat-mdc-form-field-subscript-wrapper {
    padding-bottom: 0.75em;
  }
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid) .mdc-notched-outline {
  &__leading,
  &__notch,
  &__trailing {
    border-color: var(--mdc-form-field-border-color);
  }
}

.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid):not(
    .mdc-text-field--focused
  ):hover
  .mdc-notched-outline
  .mdc-notched-outline__leading,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid):not(
    .mdc-text-field--focused
  ):hover
  .mdc-notched-outline
  .mdc-notched-outline__notch,
.mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--invalid):not(
    .mdc-text-field--focused
  ):hover
  .mdc-notched-outline
  .mdc-notched-outline__trailing {
  border-color: var(--mdc-form-field-border-color);
}

body {
  .mat-button-toggle-group-appearance-standard {
    border: none; // Gestion custom des border pour le thème
    border-radius: 0;
  }
}

.mat-button-toggle-appearance-standard {
  --yc-mat-button-toggle-checked-border-color: var(--yc-color-primary);
  --yc-mat-button-toggle-border: 1px solid var(--mat-standard-button-toggle-divider-color);

  border: var(--yc-mat-button-toggle-border);
  border-left: none;
  border-right: none;
  transition: all var(--yc-ui-animation);

  &:first-child {
    border-left: var(--yc-mat-button-toggle-border);
    border-radius: var(--mat-standard-button-toggle-shape) 0 0 var(--mat-standard-button-toggle-shape);
  }

  &:last-child {
    border-right: var(--yc-mat-button-toggle-border);
    border-radius: 0 var(--mat-standard-button-toggle-shape) var(--mat-standard-button-toggle-shape) 0;
  }

  &.mat-button-toggle-checked {
    border-color: var(--yc-mat-button-toggle-checked-border-color);

    & + .mat-button-toggle-appearance-standard {
      border-left-color: var(--yc-mat-button-toggle-checked-border-color);
    }
  }

  &:not(.mat-button-toggle-checked):not(.mat-button-toggle-disabled) {
    --mat-standard-button-toggle-hover-state-layer-opacity: 1;
    --mat-standard-button-toggle-state-layer-color: var(--yc-color-primary-tint);

    &:hover {
      --mat-standard-button-toggle-text-color: var(--yc-mat-button-toggle-checked-border-color);
      border-color: var(--yc-color-primary-lighter);

      & + .mat-button-toggle-appearance-standard {
        border-left-color: var(--yc-mat-button-toggle-checked-border-color);
      }
    }
  }

  .mat-button-toggle-button {
    z-index: 1; // Pour que le focus-overlay soit SOUS le bouton
    font-size: var(--yc-small-input-font-size);
  }

  .mat-button-toggle-label-content {
    --mat-standard-button-toggle-height: 32px;
  }
}

// TODO : utiliser un thème Angular Material
// Ticket #8e0826af
body {
  .mat-mdc-unelevated-button,
  .mat-mdc-raised-button {
    font-weight: 400;
    font-size: var(--yc-small-input-font-size);
    border-radius: 6px;
    box-shadow: none;

    &:not(:disabled) {
      background-color: var(--yc-color-primary);
      color: #fff;

      &.mat-unthemed {
        background: none;
        border: 1px solid var(--yc-color-primary);
        color: var(--yc-color-primary);
      }

      &.mat-default {
        color: #000;
        background-color: rgba(115, 102, 255, 0.06);
      }

      &.mat-warn {
        background-color: var(--yc-color-accent);
      }

      &:hover {
        box-shadow:
          inset 0 1px 0 rgba(255, 255, 255, 0.15),
          0 1px 1px rgba(0, 0, 0, 0.075);
      }
    }
  }

  .mat-mdc-unelevated-button .yuzu-icon,
  .mat-mdc-raised-button .yuzu-icon,
  .mat-mdc-outlined-button .yuzu-icon {
    // Propriétés et valeurs récupérées depuis Angular Material, appliquées à la classe .mat-icon
    display: inline-block;
    position: relative;
    vertical-align: top;
    font-size: 1.125rem;
    height: 1.125rem;
    width: 1.125rem;
    margin-left: -4px;
    margin-right: 8px;
  }

  .mat-mdc-dialog-actions {
    justify-content: flex-end;
    overflow: hidden;
  }

  .mat-button-toggle {
    .mat-pseudo-checkbox {
      display: none;
    }
  }

  .mat-mdc-optgroup-label {
    min-height: 24px;
    font-size: 0.75em;
    background-image: url(/assets/img/profile-bg.png);
    background-repeat: no-repeat;
    background-size: 120%;
    background-position: top center;
    border-radius: 0;
    opacity: 0.6;
  }

  .mat-mdc-option:hover:not(.mdc-list-item--disabled) {
    --mat-option-hover-state-layer-color: var(--yc-color-primary-tint);
    --mdc-list-list-item-hover-label-text-color: var(--yc-color-primary);
  }

  .mat-mdc-tab {
    --mat-tab-header-label-text-size: 0.75rem;
    --mat-tab-header-active-label-text-color: var(--yc-color-primary);
    --mat-tab-header-active-ripple-color: var(--yc-color-primary);
    --mdc-tab-indicator-active-indicator-color: var(--yc-color-primary);
    --mat-tab-header-active-hover-indicator-color: var(--yc-color-primary);
    --mat-tab-header-active-hover-label-text-color: var(--yc-color-primary);
    --mat-tab-header-inactive-hover-label-text-color: var(--yc-color-primary);
    --mat-tab-header-active-focus-label-text-color: var(--yc-color-primary);
    --mat-tab-header-inactive-focus-label-text-color: var(--yc-color-primary);
    --mat-tab-header-active-focus-indicator-color: var(--yc-color-primary);
  }

  div.mat-mdc-autocomplete-panel {
    max-height: min(512px, 100vh);
  }
}
